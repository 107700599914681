<template>
    <main>
        <HeaderTab 
            :title="$t('courrier_type.liste')"
        />

        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="box">
                            <CustomTable
								ref="table"
                                id_table="liste_courrier_type"
                                :busy.sync="table_busy"
                                primaryKey="model_id"
                                :items="courrier_type"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal ref="modalDuplicate" hide-footer>
            <template v-slot:modal-title>
                    {{ $t("model.dupliquer") }}
            </template>

            <div class="form-group">
                <label>{{ $t('model.nom_modele') }}</label>
                <input type="text" v-model="model_label" class="form-control">
            </div>

            <div class="col-8 m-auto">
                <b-button block pill variant="primary" @click.prevent="onSubmitDuplicate"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.dupliquer") }}</b-button>
            </div>
        </b-modal>
    </main>
</template>

<script type="text/javascript">
    import ModelMixin from "@/mixins/Model.js";
    import Navigation from "@/mixins/Navigation.js";
    import { EventBus } from 'EventBus'

	export default {
		name: 'CourrierType',
		mixins: [ModelMixin, Navigation],
		data () {
			return {
                processing: false,
                table_busy: false,
                errors: [],
                model_label: '',
                courrier_type: [],
                events_tab: {
                    'TableAction::goToAddCourrier': () => { 
                        this.$router.push({ name: 'courrierTypeConfig' })
                    },
                    'TableAction::goToEditCourrier': (model) => { 
		            	this.editModel(model.model[0].model_id) 
		            },
                    'TableAction::goToDuplicateCourrier': (model) => { 
		            	this.showDuplicateForm(model[0]) 
		            },
					'TableAction::goToDeleteCourrier': (models_ids) => {
		            	this.deleteModel(models_ids)
						.then(() => {
                            EventBus.$emit('TableAction::stopSpin')
							this.init_component()
						})
		            },
                },
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
                const list_type = ['contract_envoie', 'contract_envoie_adresse', 'contract_envoie_mail', 'validation_paiement_notify_ca', 'tiers_envoie',
                    'courrier_tiers_envoie_entete', 'horse_envoie', 'courrier_tiers_formatted', 'courrier_demande_expedition', 'contract_envoi_mail_invoice',
                    'quotation_notification', 'bilanrepro_notification', 'contract_document_envoie', 'quotation_document_notification', 'flux_paillettes',
                    'creneaux_saillie', 'invitation_ca', 'actes', 'relance_centre', 'mail_notes', 'semence_campagne']
                this.table_busy = true
                this.courrier_type = await this.loadAllModel(0, list_type)
                this.table_busy = false
			},

			editModel(model_id) {
                this.$router.push({ name: 'courrierTypeConfig', params: {model_id: model_id} })
			},

            showDuplicateForm(model) {
                this.model_label = model.model_label
                this.model_id = model.model_id
                this.$refs.modalDuplicate.show()
            },

            async onSubmitDuplicate() {
                if(!this.processing) {
                    this.processing = true
                    this.errors = []

                    if(!this.model_label) {
                        this.errors.push("model label")
                    }

                    if(this.errors.length > 0) {
                        this.processing = false
                        return false 
                    }

                    let retour = await this.duplicate_model(this.model_id, this.model_label)
                    if(retour.error == null) {
                        this.init_component()
                        this.successToast()
                        this.$refs.modalDuplicate.hide()
                    }
                    else {
                        this.failureToast(retour.message)
                    }

                    this.processing = false
                }
            }
		},

		components: {
			HeaderTab: () => import('@/components/HeaderTab'),
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable')
		}
	}

</script>